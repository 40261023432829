import axios, { AxiosResponse } from 'axios';
import { Environment, environmentMatomoSiteIds } from '../api/environment';
import { matomoSiteIdForEnv } from '../api/http';
import { eventParameters, EventTypes } from './events';

export type SendEventOptionalParams = {
  userId?: number;
  value?: number;
  name?: string;
  action?: string;
};

declare global {
  interface Window {
    _paq: any;
  }
}

const sendEvent = async (
  url: string,
  eventType: EventTypes,
  params?: SendEventOptionalParams,
  environment?: Environment,
): Promise<void> => {
  const eventParameter = eventParameters[eventType];

  if (eventParameter.nameRequired && (!params || params.name === undefined)) {
    throw new Error('Missing required name in event tracking');
  }

  if (eventParameter.valueRequired && (!params || params.value === undefined)) {
    throw new Error('Missing required value in event tracking');
  }

  if (!url) {
    throw new Error('Missing url in event tracking');
  }

  const action = params?.action ? params?.action : eventParameter.fixedAction;

  if (typeof window !== 'undefined' && window._paq) {
    if (params?.userId) {
      window._paq.push(['setUserId', `${params.userId}`]);
    }
    window._paq.push([
      'trackEvent',
      eventParameter.category,
      action,
      params?.name,
      params?.value,
    ]);
  } else {
    const matomoSiteId = environment
      ? environmentMatomoSiteIds[environment]
      : matomoSiteIdForEnv;

    const actionParam = action ? `e_a=${encodeURIComponent(action)}&` : '';
    const nameParam = params?.name
      ? `e_n=${encodeURIComponent(params.name)}&`
      : '';
    const valueParam = params?.value ? `e_v=${params.value}&` : '';
    const userIdParam = params?.userId ? `uid=${params.userId}&` : '';

    return axios.get(
      'https://matomo.wonder.inc/matomo.php?' +
        `idsite=${matomoSiteId}&` +
        `url=${encodeURIComponent(url)}&` +
        `e_c=${eventParameter.category}&` +
        userIdParam +
        actionParam +
        nameParam +
        valueParam +
        'rec=1',
    );
  }
};

export default sendEvent;

import React, { useEffect, useState } from 'react';
import { Input } from '../CommonControls';

interface IProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  valid?: boolean;
  onChange?: (value: string) => void;
}

export const FormInput: React.FunctionComponent<IProps> = ({
  type,
  valid,
  onChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [invalid, setInvalid] = useState<boolean>(false);
  const [focused, setFocused] = useState<boolean>(false);
  const [hasFocused, setHasFocused] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    if (focused) {
      setHasFocused(true);
    }
  }, [focused]);

  useEffect(() => {
    const computedValid =
      valid === null || valid === undefined || !hasFocused ? true : valid;
    setInvalid(!computedValid && !focused);
  }, [valid, focused]);

  return (
    <Input
      {...rest}
      type={type || 'text'}
      invalid={invalid}
      onFocus={(e) => {
        setFocused(true);
        onFocus && onFocus(e);
      }}
      onBlur={(e) => {
        setFocused(false);
        onBlur && onBlur(e);
      }}
      onChange={handleChange}
    />
  );
};

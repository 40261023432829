export type EventTypes =
  // Accounts
  | 'accounts_signup'
  | 'accounts_login'
  | 'accounts_enter_signup_code'
  | 'accounts_download_dynascore_electron_app'
  | 'accounts_donload_dynascore_premiere_extention'
  | 'accounts_enter_payment_method'
  | 'accounts_create_api_key'
  | 'accounts_remove_api_key'
  | 'accounts_view_api_pricing'
  | 'accounts_navigate'
  | 'accounts_paid_change_plan'
  | 'accounts_paid_upgrade_to_paid'

  // Dynascore Generic
  | 'dynascore_run_search'
  | 'dynascore_play_sample'
  | 'dynascore_click_dynascore'
  | 'dynascore_compose'
  | 'dynascore_upsert_marker'
  | 'dynascore_remove_marker'
  | 'dynascore_hatch'
  | 'dynascore_share_sample'
  | 'dynascore_share_track'

  // Dynascore Electron app
  | 'dynascore_electron_open'
  | 'dynascore_electron_connected'
  | 'dynascore_electron_listen_to_track'
  | 'dynascore_electron_help'
  | 'dynascore_electron_settings_adjust_directory'

  // Dynascore Premiere Pro Extension
  | 'dynascore_premiere_open'
  | 'dynascore_premiere_connected'
  | 'dynascore_premiere_hatch_new_version'
  | 'dynascore_premiere_select_dynascore'
  | 'dynascore_premiere_reveal_in_finder'
  | 'dynascore_premiere_reveal_in_project'
  | 'dynascore_premiere_place_on_timeline';

export type EventParameters = {
  category: string;
  nameRequired: boolean;
  valueRequired: boolean;
  fixedAction?: string;
};

let createEventParams = (
  category: string,
  nameRequired: boolean,
  valueRequired: boolean,
  fixedAction?: string,
) => {
  return {
    category,
    nameRequired,
    valueRequired,
    fixedAction,
  };
};

export const eventParameters: { [event in EventTypes]: EventParameters } = {
  accounts_signup: createEventParams('Signup', false, false, 'New Signup'),
  accounts_login: createEventParams('Login', false, false, 'Login'),
  accounts_enter_signup_code: createEventParams(
    'Signup',
    false,
    false,
    'Enter Signup Code',
  ),
  accounts_download_dynascore_electron_app: createEventParams(
    'Downloads',
    false,
    false,
    'Download Dynascore App',
  ),
  accounts_donload_dynascore_premiere_extention: createEventParams(
    'Downloads',
    false,
    false,
    'Download Premiere Extension',
  ),
  accounts_enter_payment_method: createEventParams(
    'Billing',
    false,
    false,
    'Entered Payment Method',
  ),
  accounts_create_api_key: createEventParams('API', false, false, 'Create Key'),
  accounts_remove_api_key: createEventParams('API', false, false, 'Remove Key'),
  accounts_view_api_pricing: createEventParams(
    'API',
    false,
    false,
    'View Pricing',
  ),
  accounts_navigate: createEventParams('Accounts Navigation', false, false),
  accounts_paid_upgrade_to_paid: createEventParams(
    'Paid Transaction',
    true,
    false,
    'Upgrade to Paid Plan',
  ),
  accounts_paid_change_plan: createEventParams(
    'Paid Transaction',
    true,
    false,
    'Change Paid Plan',
  ),

  // Dynascore Generic
  dynascore_play_sample: createEventParams(
    'Dynascores',
    true,
    false,
    'Play Dynascore',
  ),
  dynascore_run_search: createEventParams('Search', true, false),
  dynascore_click_dynascore: createEventParams(
    'Dynascores',
    true,
    false,
    'Open Dynascore',
  ),
  dynascore_compose: createEventParams(
    'Hatching',
    false,
    false,
    'Begin Hatching',
  ),
  dynascore_upsert_marker: createEventParams('Markers', true, true),
  dynascore_remove_marker: createEventParams('Markers', true, false),
  dynascore_hatch: createEventParams('Hatching', true, false, 'Hatch Track'),
  dynascore_share_sample: createEventParams(
    'Sharing',
    true,
    false,
    'Share Sample',
  ),
  dynascore_share_track: createEventParams(
    'Sharing',
    true,
    false,
    'Share Track',
  ),

  // Dynascore Electron app
  dynascore_electron_open: createEventParams(
    'Open Application',
    false,
    false,
    'Electron App',
  ),
  dynascore_electron_connected: createEventParams(
    'Open Application',
    false,
    false,
    'Electron App Connected',
  ),
  dynascore_electron_listen_to_track: createEventParams(
    'Tracks',
    true,
    false,
    'Play Track',
  ),
  dynascore_electron_help: createEventParams(
    'Help',
    false,
    false,
    'Request Help',
  ),
  dynascore_electron_settings_adjust_directory: createEventParams(
    'Settings',
    false,
    false,
    'Change Download Directory',
  ),

  // Dynascore Premiere Pro Extension
  dynascore_premiere_open: createEventParams(
    'Open Application',
    false,
    false,
    'Premiere Pro Extension',
  ),
  dynascore_premiere_connected: createEventParams(
    'Open Application',
    false,
    false,
    'Premiere Pro Extension Connected',
  ),
  dynascore_premiere_hatch_new_version: createEventParams(
    'Tracks',
    true,
    false,
    'Make New Version',
  ),
  dynascore_premiere_select_dynascore: createEventParams(
    'Dynascores',
    true,
    false,
    'Select Dynascore',
  ),
  dynascore_premiere_reveal_in_finder: createEventParams(
    'Tracks',
    true,
    false,
    'Reveal Location',
  ),
  dynascore_premiere_reveal_in_project: createEventParams(
    'Tracks',
    true,
    false,
    'Reveal in Project',
  ),
  dynascore_premiere_place_on_timeline: createEventParams(
    'Tracks',
    true,
    false,
    'Place on Timeline',
  ),
};

import * as React from "react";
import { environmentColors, environmentNames } from "../../../../../common/api/environment";
import { environment } from "../../../../../common/api/http";
import { LinkButton, SecondaryLink } from "../../../../../common/client/components/CommonControls";

import styles from "./LoginChrome.module.scss";
const logo = require("../../../static/wonder_logo.svg").default;

interface Props {
  title?: string;
  icon?: string;
  showTopButtons?: boolean;
  noLinks?: boolean;
}

export const LoginChrome: React.FunctionComponent<Props> = ({
  title,
  icon,
  showTopButtons = true,
  noLinks = false,
  children,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        {noLinks && (
          <span className={styles.logo} key="logo">
            <img src={logo} />
          </span>
        )}
        {!noLinks && (
          <a href="/" className={styles.logo} key="logo">
            <img src={logo} />
          </a>
        )}
        {showTopButtons && (
          <div className={styles.topRight}>
            <SecondaryLink href="/login" key="signin">
              Sign In
            </SecondaryLink>
            <LinkButton href="/signup" key="signup">
              TRY FREE
            </LinkButton>
          </div>
        )}
        {environment !== "prod" && (
          <div style={{ color: environmentColors[environment] }}>
            {environmentNames[environment]}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.innerContent}>
          {title && <div className={styles.title}>{title}</div>}
          {children}
        </div>
      </div>
    </div>
  );
};

import { App, appUrls } from './apps';

export type UnifiedMode = 'emailvalidate' | 'signup' | 'signin' | 'iossignin';

export const WonderAppNames: { [K in App]: string } = {
  wonder: 'Wonder',
  dynascore: 'Dynascore',
  roam: 'Roam',
  dingdong: 'DingDong',
};

export const getLoginLink = (app: App, dest: string) => {
  return new URL(
    '/login?app=${app}&dest=${encodeURIComponent(dest)}',
    appUrls['wonder'],
  ).href;
};

import React, { useState } from "react";
import { LoginChrome } from "../../components/LoginChrome/LoginChrome";
import { FormInput } from "../../../../../common/client/components/FormInput/FormInput";
import { Button, Link } from "../../../../../common/client/components/CommonControls";
import sendEvent from "../../../../../common/usertracking/sendEvent";
import { WonderAppNames, UnifiedMode } from "../../../../../common/accounts/links";
import { App } from "../../../../../common/accounts/apps";

import styles from "./SignupInPage.module.css";

interface IProps {
  app?: App;
  dest?: string;
  errorMessage?: string;
  email?: string;
  planId?: number;
  mode?: UnifiedMode;
  acct?: string;
  acctName?: string;
}

const sendSignupEvent = (mode: UnifiedMode) => {
  switch (mode) {
    case "signin":
    case "iossignin":
      sendEvent(window.location.href, "accounts_login");
      break;
    case "signup":
      sendEvent(window.location.href, "accounts_signup");
      break;
  }
};

export const SignupInPage: React.FunctionComponent<IProps> = (props) => {
  const [email, setEmail] = useState<string>(props.email || "");
  let app = props.app ?? "wonder";
  let mode = props.mode ?? "signup";

  let title: string;
  switch (mode) {
    case "signin":
    case "iossignin":
      if (props.acctName) {
        title = `Sign up for ${WonderAppNames[app]} for ${props.acctName}`;
      } else {
        title = `Sign in to ${WonderAppNames[app]}`;
      }

      break;
    case "signup":
      title = "First, enter your email";
      break;
  }

  return (
    <LoginChrome title={title} showTopButtons={false} noLinks={mode === "iossignin"}>
      <form className={styles.form} method="POST" onSubmit={() => sendSignupEvent(mode)}>
        {props.errorMessage && <div className={styles.error}>{props.errorMessage}</div>}
        {props.dest && <input type="hidden" name="dest" value={props.dest} />}
        {props.planId && <input type="hidden" name="planId" value={props.planId} />}
        <input type="hidden" name="app" value={app} />
        <input type="hidden" name="mode" value={mode} />
        <input type="hidden" name="acct" value={props.acct} />
        <div className={styles.field}>
          <div className={styles.label}>Email</div>
          <FormInput name="email" type="email" value={email} onChange={setEmail} required />
        </div>
        {mode == "signup" && (
          <>
            <input type="hidden" name="agreement" value="true" />
            <label className={styles.legal}>
              By clicking below, you agree to the Wonder{" "}
              <Link href="https://www.wonder.inc/terms" target="_blank">
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link href="https://www.wonder.inc/privacy-policy" target="_blank">
                Privacy Policy
              </Link>
              .
            </label>
            <div className={styles.button}>
              <Button type="submit">Agree &amp; Continue</Button>
            </div>
          </>
        )}
        {(mode === "signin" || mode === "iossignin") && (
          <div className={styles.button}>
            <Button type="submit">Sign In with Email</Button>
          </div>
        )}
      </form>
    </LoginChrome>
  );
};

import Color from 'color';
import React from 'react';
import theme, { styled } from '../theme';
import { LoadingSpinner } from './LoadingSpinner/LoadingSpinner';

export const Button = styled.button<{ compact?: boolean }>`
  &&& {
    border: none;
    border-radius: 50px;
    text-decoration: none;
    background-color: ${(props) => props.theme.buttonBg};
    color: ${(props) => props.theme.buttonFg};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: ${(props) =>
      props.compact ? '0.25rem 0.75rem' : '0.5rem 1.5rem'};
    font-size: 1rem;
    font-weight: ${(props) => (props.compact ? 'lighter' : 'normal')};
    outline: none;
    text-align: center;
  }

  &&&:hover:not(:disabled) {
    filter: contrast(1.5);
  }

  &&&:disabled {
    background-color: ${(props) => props.theme.buttonDisabledBg};
    cursor: not-allowed;
  }
`;

type InProgressButtonProps = {
  inProgress?: boolean;
  compact?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'submit' | 'button' | 'reset';
};

export const InProgressButton: React.FC<InProgressButtonProps> = ({
  inProgress,
  compact,
  disabled,
  onClick,
  type,
  children,
}) => {
  if (inProgress) {
    return (
      <RawRelativePositionedButton disabled={true} type={type}>
        <CenteredFloating>
          <LoadingSpinner color={Color(theme.buttonFg)} size="1.1em" />
        </CenteredFloating>
        <Invisible>{children}</Invisible>
      </RawRelativePositionedButton>
    );
  }
  return (
    <Button compact={compact} disabled={disabled} onClick={onClick} type={type}>
      {children ? children : ''}
    </Button>
  );
};

export const DangerButton = styled(Button)`
  &&& {
    background-color: ${(props) => props.theme.danger};
  }
`;

export const OutlineButton = styled(Button)`
  &&& {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.buttonBg};
    color: ${(props) => props.theme.titleText};
  }

  &&&:disabled {
    background-color: transparent;
    border-color: ${(props) => props.theme.buttonDisabledBg};
    color: ${(props) => props.theme.buttonDisabledBg};
  }
`;

export const Link = styled.a`
  &&& {
    color: ${(props) => props.theme.primary};
    text-decoration: none;
    cursor: pointer;
  }
`;

export const SecondaryLink = styled(Link)`
  &&& {
    color: ${(props) => props.theme.titleText};
  }
`;

export const DangerLink = styled(Link)`
  &&& {
    color: ${(props) => props.theme.danger};
  }
`;

export const LinkButton = styled.a<{ compact?: boolean }>`
  &&& {
    border: none;
    border-radius: 50px;
    text-decoration: none;
    background-color: ${(props) => props.theme.buttonBg};
    color: ${(props) => props.theme.buttonFg};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: ${(props) =>
      props.compact ? '0.25rem 0.75rem' : '0.5rem 1.5rem'};
    font-size: 1rem;
    font-weight: ${(props) => (props.compact ? 'lighter' : 'normal')};
    outline: none;
    text-align: center;
  }

  &&&:hover:not(:disabled) {
    filter: contrast(1.5);
  }

  &&&:disabled {
    background-color: ${(props) => props.theme.buttonDisabledBg};
    cursor: not-allowed;
  }
`;

export const OutlineLinkButton = styled(LinkButton)`
  &&& {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.buttonBg};
    color: ${(props) => props.theme.titleText};
  }

  &&&:disabled {
    background-color: transparent;
    border-color: ${(props) => props.theme.buttonDisabledBg};
    color: ${(props) => props.theme.buttonDisabledBg};
  }
`;

export const ContactUs: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Link href="https://www.wonder.inc/contact-us/">
      {text || 'Contact us'}
    </Link>
  );
};

export const Input = styled.input<{ invalid?: boolean }>`
  &&& {
    background-color: black;
    border: 1px solid
      ${(props) => (props.invalid ? props.theme.danger : props.theme.border)};
    color: ${(props) => props.theme.titleText};
    font-size: 1rem;
    outline: 0;
    padding: 0.75rem 1.5rem;
  }

  &&&:focus {
    border-color: ${(props) => props.theme.titleText};
  }

  &&&:disabled {
    border-color: ${(props) => props.theme.buttonDisabledBg};
    color: ${(props) => props.theme.buttonDisabledBg};
  }
`;

export const Select = styled.select`
  &&& {
    background-color: black;
    border: 1px solid ${(props) => props.theme.border};
    color: ${(props) => props.theme.titleText};
    font-size: 1rem;
    outline: 0;
    padding: 0.75rem 1.5rem;
  }

  &&&:focus {
    border-color: ${(props) => props.theme.titleText};
  }

  &&&:disabled {
    border-color: ${(props) => props.theme.buttonDisabledBg};
    color: ${(props) => props.theme.buttonDisabledBg};
  }
`;

export const PageTitle = styled.div`
  font-size: 3rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  align-self: stretch;
  min-width: 50vw;
  color: ${(props) => props.theme.titleText};

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

const RawRelativePositionedButton = styled(Button)`
  position: relative;
`;

const Invisible = styled.span`
  visibility: hidden;
`;

const CenteredFloating = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

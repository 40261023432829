import baseStyled, { ThemedStyledInterface } from 'styled-components';

const theme = {
  wonderRed: 'rgb(255, 55, 37)',
  wonderYellow: 'rgb(255, 230, 0)',
  wonderGreen: 'rgb(0, 235, 80)',
  wonderCyan: 'rgb(32, 179, 255)',
  wonderIndigo: 'rgb(100, 0, 230)',
  wonderPurple: 'rgb(210, 0, 230)',
  wonderBlack: 'rgb(0, 0, 0)',

  // TODO: I don't really know how to define a color theme. This is my best attempt.
  fg: 'white',
  bg: 'rgb(25, 25, 25)',
  mainText: '#999999',
  titleText: 'white',
  sidebar: 'black',
  primary: '#00EB50',
  secondary: '#D200E6',
  danger: '#FF3725',
  border: '#848484',
  separator: '#939393',
  separatorSecondary: '#7C7C7C',

  // I feel like these should use things from above ... but I dunno.
  buttonBg: '#00EB50',
  buttonDisabledBg: '#AFB9C5',
  buttonFg: 'black',
};

export default theme;
export const styled = baseStyled as ThemedStyledInterface<typeof theme>;

import "../../../common/api/loadWebEnvironment"; // Load first to set environment.
import * as React from "react";
import * as ReactDOM from "react-dom";
import { SignupInPage } from "./pages/SignupInPage/SignupInPage";
import { ThemeProvider } from "styled-components";
import theme from "../../../common/client/theme";

import "./index.module.scss"; // Styles

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SignupInPage {...window["appData"]} />
  </ThemeProvider>,
  document.getElementById("root")
);
